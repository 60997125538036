import React from "react";

interface Props {
  isActive?: boolean;
}

const UserAccount: React.FC<Props> = ({ isActive }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0C2.688 0 0 2.688 0 6C0 9.312 2.688 12 6 12C9.312 12 12 9.312 12 6C12 2.688 9.312 0 6 0ZM6 2.4C7.158 2.4 8.1 3.342 8.1 4.5C8.1 5.658 7.158 6.6 6 6.6C4.842 6.6 3.9 5.658 3.9 4.5C3.9 3.342 4.842 2.4 6 2.4ZM6 10.8C4.782 10.8 3.342 10.308 2.316 9.072C3.36696 8.24743 4.66417 7.79928 6 7.79928C7.33583 7.79928 8.63304 8.24743 9.684 9.072C8.658 10.308 7.218 10.8 6 10.8Z"
        fill={isActive ? "var(--color-dark-1)" : "var(--color-dark-3)"}
      />
    </svg>
  );
};

export default UserAccount;
