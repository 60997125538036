import React from "react";

interface Props {
  width?: number;
  height?: number;
}

const ProfileIcon: React.FC<Props> = ({ width = 54, height = 54 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="27" cy="27" r="27" fill="var(--color-primary)" />
      <path
        d="M27 15C20.376 15 15 20.376 15 27C15 33.624 20.376 39 27 39C33.624 39 39 33.624 39 27C39 20.376 33.624 15 27 15ZM27 19.8C29.316 19.8 31.2 21.684 31.2 24C31.2 26.316 29.316 28.2 27 28.2C24.684 28.2 22.8 26.316 22.8 24C22.8 21.684 24.684 19.8 27 19.8ZM27 36.6C24.564 36.6 21.684 35.616 19.632 33.144C21.7339 31.4949 24.3283 30.5986 27 30.5986C29.6717 30.5986 32.2661 31.4949 34.368 33.144C32.316 35.616 29.436 36.6 27 36.6Z"
        fill="white"
      />
    </svg>
  );
};

export default ProfileIcon;
