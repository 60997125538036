import React from "react";

import styles from "./Icons.module.scss";

interface Props {
  isActive?: boolean;
  width?: number;
  height?: number;
  on?: boolean;
  number?: number;
  onClick?: () => void;
}

const Bell: React.FC<Props> = ({
  isActive,
  width = 12,
  height = 12,
  onClick,
  on = false,
  number,
}) => {
  return (
    <div onClick={onClick} className={styles.notification_container}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.33262 12C6.7042 12.0005 7.06669 11.8852 7.36971 11.6701C7.67273 11.455 7.90122 11.1509 8.02342 10.8H4.64182C4.76402 11.1509 4.9925 11.455 5.29552 11.6701C5.59854 11.8852 5.96104 12.0005 6.33262 12ZM10.5326 7.5516V4.8C10.5326 2.8698 9.22162 1.2438 7.44562 0.7548C7.26982 0.312 6.84022 0 6.33262 0C5.82502 0 5.39542 0.312 5.21962 0.7548C3.44362 1.2444 2.13262 2.8698 2.13262 4.8V7.5516L1.10842 8.5758C1.05259 8.63142 1.00832 8.69754 0.978148 8.77034C0.947977 8.84314 0.932504 8.92119 0.932618 9V9.6C0.932618 9.75913 0.995832 9.91174 1.10835 10.0243C1.22088 10.1368 1.37349 10.2 1.53262 10.2H11.1326C11.2917 10.2 11.4444 10.1368 11.5569 10.0243C11.6694 9.91174 11.7326 9.75913 11.7326 9.6V9C11.7327 8.92119 11.7173 8.84314 11.6871 8.77034C11.6569 8.69754 11.6126 8.63142 11.5568 8.5758L10.5326 7.5516Z"
          fill={isActive ? "var(--color-dark-1)" : "var(--color-dark-3)"}
        />
      </svg>
      {on && (
        <div
          className={`${styles.notification_on} ${styles.notification_small}`}
        >
          {on && number && number > 0 && <p>{number}</p>}
        </div>
      )}
    </div>
  );
};

export default Bell;
