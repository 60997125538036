import { useLazyQuery, useMutation } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import styled from "styled-components";

import {
  CONFIRM_SIGNUP,
  LOGIN,
  RESEND_CONFIRMATION_CODE,
} from "../../../api/graphql/auth";
import { GET_USER_INFO } from "../../../api/graphql/user";
import { storeAuthResult } from "../../../api/utils/token.utils";
import useAuth from "../../../hooks/useAuth";
import {
  ConfirmSignupRequest,
  ConfirmSignupResponse,
  LoginRequest,
  ResendConfirmationCodeRequest,
  ResendConfirmationCodeResponse,
  TokenResponse,
} from "../../../types/auth.type";
import { RoleEnum } from "../../../types/enum.types";
import {
  GetUserInfoResponse,
  UserCredentials,
} from "../../../types/user.types";
import { decipher } from "../../../utils/hash";
import { HOME_PAGE, LOGIN_PAGE } from "../../../utils/pages";
import { ParagraphError } from "../../errors/ParagraphError";
import SimpleInput from "../../Input/SimpleInput";
import SimpleButton from "../../SimpleButton/SimpleButton";
import CenteredBox from "../CenteredBox";

const VerifyEmailText = styled.div`
  h1 {
    text-align: center;
    font-family: var(--font-title-bold);
    font-weight: bolder;
    span {
      &:first-child {
        color: var(--color-dark-1);
      }
      &:last-child {
        color: var(--color-primary);
      }
    }
  }

  p {
    font-weight: bolder;
    span {
      color: var(--color-primary);
    }
  }
`;

const BoldZon = styled.p`
  font-family: var(--font-title-bold);
`;

const ResendCodeParagraph = styled.p`
  text-align: center;
  font-weight: bolder;
  font-size: 80%;
  margin-top: 0;
  span {
    font-family: var(--font-title-bold);
    color: var(--color-primary);
    cursor: pointer;
  }
`;

const SmallParagraph = styled.p`
  text-align: center;
  font-weight: bolder;
  font-size: 60%;
  margin-top: 0;
  span {
    font-family: var(--font-title-bold);
    color: var(--color-primary);
    cursor: pointer;
  }
`;

function VerifyEmail() {
  const [code, setCode] = useState<string>("");
  const [userCredentials, setUserCredentials] = useState<UserCredentials>({
    email: "",
    password: "",
  });
  const [sendCodeMessage, setSendCodeMessage] = useState<string>();
  const { setUser, setIsAuthenticated } = useAuth();
  const router = useRouter();
  const companyId = router.query.companyId as string;

  const [submitCode, { error: submitCodeError, loading: submitCodeLoading }] =
    useMutation<ConfirmSignupResponse, ConfirmSignupRequest>(CONFIRM_SIGNUP);

  const [resendCodeTrigger, { loading: resendLoading }] = useMutation<
    ResendConfirmationCodeResponse,
    ResendConfirmationCodeRequest
  >(RESEND_CONFIRMATION_CODE);

  const [loginTrigger] = useMutation<TokenResponse<"login">, LoginRequest>(
    LOGIN
  );

  const [getUserTrigger] = useLazyQuery<GetUserInfoResponse>(GET_USER_INFO);

  const resendCode = async () => {
    if (userCredentials?.email) {
      setSendCodeMessage("Le code a été envoyé à votre adresse e-mail");
      return resendCodeTrigger({
        variables: {
          ResendConfirmationCodeInput: { username: userCredentials.email },
        },
      });
    }
  };

  const handleSubmit = async () => {
    if (userCredentials.email && userCredentials.password) {
      setSendCodeMessage("Le code a été envoyé à votre adresse e-mail");

      const response = await submitCode({
        variables: {
          ConfirmSignupUserInput: {
            username: userCredentials.email,
            code,
          },
        },
      });
      if (!response.errors) {
        const loginResponse = await loginTrigger({
          variables: {
            loginInput: {
              username: userCredentials.email,
              password: userCredentials.password,
            },
          },
        });

        const data = loginResponse.data?.login.AuthenticationResult;
        if (response.data)
          storeAuthResult(data?.AccessToken, data?.IdToken, data?.RefreshToken);
        if (!response.errors) {
          // get the user data
          const userResponse = await getUserTrigger();
          const userData = userResponse.data?.getUserInfo;
          setUser(userData);
          setIsAuthenticated(true);
          localStorage.removeItem("email");
          localStorage.removeItem("password");
          const redirectUrl = localStorage.getItem("redirect");
          if (redirectUrl) {
            router.replace(redirectUrl);
            localStorage.removeItem("redirect");
          } else router.replace(HOME_PAGE);
        }
      }
    }
  };

  // get the user credentials from the local storage
  useEffect(() => {
    const email = localStorage.getItem("email");
    const hashedPassword = localStorage.getItem("password");
    if (hashedPassword) {
      const password = decipher(hashedPassword);
      if (!email || !password) router.replace(LOGIN_PAGE);
      else
        setUserCredentials({
          email,
          password,
        });
    }
  }, []);

  return (
    <div className={companyId ? "company-variables" : "applicant-variables"}>
      <CenteredBox
        width="27%"
        question="Déjà un compte ?"
        questionColor="var(--color-primary)"
        onSubmit={() => {
          handleSubmit();
        }}
        onActionClick={() => {
          router.replace(LOGIN_PAGE);
        }}
        role={companyId ? RoleEnum.COMPANY : RoleEnum.APPLICANT}
      >
        <VerifyEmailText>
          <h1>
            <span>Vérifiez votre</span> <span>mail</span>
          </h1>
          <p>
            Nous avons envoyé un lien de connexion à{" "}
            <span>{userCredentials.email} </span>
          </p>
          <p>
            Veuillez confirmer la création de votre compte afin de pouvoir
            compléter votre profil et accéder à nos services.
          </p>
          <BoldZon>
            Vous n’avez rien reçu ? Consultez vos spam ou renvoyer un mail.
          </BoldZon>
        </VerifyEmailText>
        <br />
        <SimpleInput
          id="code"
          required
          placeholder="Code"
          type="text"
          name="code"
          onChange={(e) => setCode(e.target.value)}
          value={code}
        />
        <SimpleButton
          disabled={submitCodeLoading}
          type="submit"
          label="Valider mon compte"
          width={"100%"}
        />
        <ResendCodeParagraph>
          Vous n'avez pas reçu de code?{" "}
          <span
            onClick={() => {
              resendCode();
            }}
          >
            {" "}
            &nbsp;Renvoyer le code{" "}
          </span>
        </ResendCodeParagraph>
        <ParagraphError center isError={true}>
          {submitCodeError?.message}
        </ParagraphError>
        {sendCodeMessage && (
          <>
            <SmallParagraph>
              {sendCodeMessage} &nbsp; &nbsp;
              {(resendLoading || submitCodeLoading) && (
                <CircularProgress size={"10px"} />
              )}
            </SmallParagraph>
          </>
        )}
      </CenteredBox>
    </div>
  );
}

export default VerifyEmail;
