import { useLazyQuery, useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import React, { useState } from "react";
import styled from "styled-components";

import { LOGIN, RESEND_CONFIRMATION_CODE } from "../../../api/graphql/auth";
import { GET_USER_INFO } from "../../../api/graphql/user";
import { storeAuthResult } from "../../../api/utils/token.utils";
import useAuth from "../../../hooks/useAuth";
import { useBreakpoint } from "../../../hooks/useBreakpoint";
import {
  LoginErrorResponse,
  LoginRequest,
  ResendConfirmationCodeRequest,
  ResendConfirmationCodeResponse,
  TokenResponse,
} from "../../../types/auth.type";
import { Breakpoint, RoleEnum } from "../../../types/enum.types";
import {
  GetUserInfoResponse,
  UserCredentials,
} from "../../../types/user.types";
import { cipher } from "../../../utils/hash";
import {
  ENTREPRISE_OFFERS_PAGE,
  FORGET_PASSWORD_PAGE,
  HOME_PAGE,
  INSCRIPTION_PAGE,
  VERIFY_EMAIL_PAGE,
} from "../../../utils/pages";
import { ParagraphError } from "../../errors/ParagraphError";
import SimpleInput from "../../Input/SimpleInput";
import SimpleButton from "../../SimpleButton/SimpleButton";
import CenteredBox from "../CenteredBox";

const ForgetPasswordContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  p {
    margin: 0;
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
  }
`;

interface Props {
  action?: () => void;
  surrounded?: boolean;
}

const Login: React.FC<Props> = ({ action, surrounded }) => {
  const [userCredentials, setUserCredentials] = useState<UserCredentials>({
    email: "",
    password: "",
  });
  const { setUser, setIsAuthenticated } = useAuth();
  const router = useRouter();
  const breakpoint = useBreakpoint();

  const [loginTrigger, { loading, error }] = useMutation<
    TokenResponse<"login">,
    LoginRequest
  >(LOGIN);

  const [getUserTrigger, { loading: getUserLoading }] =
    useLazyQuery<GetUserInfoResponse>(GET_USER_INFO);

  const [resendCodeTrigger] = useMutation<
    ResendConfirmationCodeResponse,
    ResendConfirmationCodeRequest
  >(RESEND_CONFIRMATION_CODE);

  const isMedium =
    breakpoint.includes(Breakpoint.XS) && !breakpoint.includes(Breakpoint.LG);

  const redirectedPage =
    typeof router.query.redirectTo === "string"
      ? router.query.redirectTo
      : null;

  const handleSubmit = async () => {
    const response = await loginTrigger({
      variables: {
        loginInput: {
          username: userCredentials.email,
          password: userCredentials.password,
        },
      },
    });
    if (response.errors) {
      const error = response.errors[0].extensions as LoginErrorResponse;
      const errorType = error.exception.response.code;
      if (errorType === "UserNotConfirmedException") {
        // resend code
        const result = await resendCodeTrigger({
          variables: {
            ResendConfirmationCodeInput: { username: userCredentials.email },
          },
        });
        if (!result.errors) {
          const hashedPassword = cipher(userCredentials.password);
          localStorage.setItem("email", userCredentials.email);
          localStorage.setItem("password", hashedPassword);
          if (isMedium && action) action();
          else router.push(VERIFY_EMAIL_PAGE);
        }
      }
    } else {
      const data = response.data?.login.AuthenticationResult;
      if (response.data)
        storeAuthResult(data?.AccessToken, data?.IdToken, data?.RefreshToken);
      if (!response.errors) {
        // get the user data
        const userResponse = await getUserTrigger();
        const userData = userResponse.data?.getUserInfo;
        setUser(userData);
        setIsAuthenticated(true);
        const redirectTo = localStorage.getItem("redirect");
        localStorage.removeItem("redirect");
        switch (userData?.role) {
          case RoleEnum.COMPANY:
            router.push(redirectedPage ?? ENTREPRISE_OFFERS_PAGE);
            break;
          case RoleEnum.APPLICANT:
            router.push(redirectTo ?? redirectedPage ?? HOME_PAGE);
            break;
          default:
            break;
        }
      }
    }
  };

  const handleInput = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setUserCredentials((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));
  };

  return (
    <div>
      <CenteredBox
        question="Nouveau sur Swala"
        button_label="S'inscrire"
        onSubmit={() => {
          handleSubmit();
        }}
        onActionClick={() => {
          router.push(INSCRIPTION_PAGE);
        }}
        surrounded={surrounded}
        width={"23%"}
        id="connexion"
      >
        <SimpleInput
          id="email"
          required
          placeholder="E-mail"
          type="email"
          name="email"
          value={userCredentials.email}
          onChange={handleInput}
        />
        <SimpleInput
          id="password"
          required
          placeholder="Mot de passe"
          type="password"
          name="password"
          onChange={handleInput}
          value={userCredentials.password}
        />
        <ForgetPasswordContainer>
          <p
            onClick={() => {
              router.push(FORGET_PASSWORD_PAGE);
            }}
          >
            Mot de passe oublié ?
          </p>
        </ForgetPasswordContainer>

        <SimpleButton
          disabled={loading || getUserLoading}
          type="submit"
          label="Connexion"
          width={"100%"}
        />
        <ParagraphError center isError={true}>
          {error?.message ? "Email ou mot de passe incorrect." : ""}
        </ParagraphError>
      </CenteredBox>
    </div>
  );
};

export default Login;
