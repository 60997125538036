import React from "react";

interface Props {
  isActive?: boolean;
  onClick?: () => void;
}

const Heart: React.FC<Props> = ({ isActive, onClick }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M1.02741 6.52682L5.21911 10.9046C5.64864 11.3532 6.36549 11.3532 6.79501 10.9046L10.7187 6.80676C10.8061 6.73159 10.8909 6.65133 10.9726 6.56598C12.3425 5.13532 12.3425 2.81577 10.9726 1.38511C9.60499 -0.0431647 7.38912 -0.0455496 6.01874 1.37795L5.98812 1.34594C4.61823 -0.0847152 2.39729 -0.0847152 1.02741 1.34594C-0.34247 2.7766 -0.34247 5.09616 1.02741 6.52682Z"
        fill={isActive ? "var(--color-dark-1)" : "var(--color-dark-3)"}
      />
    </svg>
  );
};

export default Heart;
