import { Modal } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import useAuth from "../../hooks/useAuth";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { Breakpoint, RoleEnum } from "../../types/enum.types";
import {
  APPLICANT_HOME_PAGE,
  ARTICLES_PAGE,
  ENTREPRISES_PAGE,
  HOME_PAGE,
  INSCRIPTION_PAGE,
  LOGIN_PAGE,
  NOTIFICATIONS_PAGE,
  OFFERS_PAGE,
  POURQUOI_SWALA_CANDIDATE,
  POURQUOI_SWALA_PAGE,
  POURQUOI_SWALA_RECRUITER,
} from "../../utils/pages";
import BurgerCollapse from "../assets/icons/BurgerCollapse";
import Logo from "../assets/icons/Logo";
import Notification from "../assets/icons/Notification";
import NavLink from "../NavLink";
import styles from "./Header.module.scss";
import ProfileResponsiveNav from "./ProfileResponsiveNav";
import SigninModal from "./SigninModal";
import SignupModal from "./SignupModal";
import UserProfile from "./UserProfile";
import VerifyEmailModal from "./VerifyEmailModal";

const Header: React.FC = () => {
  const [modal, setModal] = useState(false);
  const [signinModal, setSigninModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [verifyEmailModal, setVerifyEmailModal] = useState(false);
  const breakpoint = useBreakpoint();

  const signupButtonRef = useRef<HTMLElement>();
  const navbarContainer = useRef<HTMLElement | null>(null);
  const burgerRef = useRef<HTMLElement | null>(null);

  const router = useRouter();
  const { user } = useAuth();

  let isSticky = false;
  const isMedium =
    breakpoint.includes(Breakpoint.XS) && !breakpoint.includes(Breakpoint.LG);
  const handleNavigation = () => {
    if (!window) return null;
    if (window.scrollY > 140 && !isSticky) {
      signupButtonRef.current?.classList.add(styles.fixed);
      signupButtonRef.current?.classList.remove(styles.remove_fixed);

      if (user) {
        navbarContainer.current?.classList.add(styles.nav_fixed);
        navbarContainer.current?.classList.remove(styles.remove_nav_fixed);
      }

      if (!user && isMedium) {
        burgerRef.current?.classList.add(styles.fixed);
        burgerRef.current?.classList.remove(styles.remove_fixed);
      }
      isSticky = true;
    } else if (window.scrollY < 100 && isSticky) {
      signupButtonRef.current?.classList.remove(styles.fixed);
      signupButtonRef.current?.classList.add(styles.remove_fixed);

      if (user) {
        navbarContainer.current?.classList.remove(styles.nav_fixed);
        navbarContainer.current?.classList.add(styles.remove_nav_fixed);
      }

      if (!user && isMedium) {
        burgerRef.current?.classList.remove(styles.fixed);
        burgerRef.current?.classList.add(styles.remove_fixed);
      }
      isSticky = false;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);
    return () => window.removeEventListener("scroll", handleNavigation);
  }, [isMedium]);

  return (
    <nav
      ref={navbarContainer}
      className={`${styles.navigation_container} ${
        user && styles.navigation_user
      }`}
    >
      <div className={styles.navigation_links}>
        <div
          onClick={() => {
            router.push(HOME_PAGE);
          }}
        >
          <Logo />
        </div>

        <ul>
          <NavLink
            label="Accueil"
            path={HOME_PAGE}
            secondPath={APPLICANT_HOME_PAGE}
          />
          <NavLink label="Offres d’emploi" path={OFFERS_PAGE} />
          <NavLink label="Entreprises qui recrutent" path={ENTREPRISES_PAGE} />
          <NavLink label="Articles" path={ARTICLES_PAGE} />
          <NavLink
            label="Pourquoi Swala ?"
            path={
              user === undefined
                ? POURQUOI_SWALA_PAGE
                : user.role === RoleEnum.APPLICANT
                ? POURQUOI_SWALA_CANDIDATE
                : POURQUOI_SWALA_RECRUITER
            }
          />
        </ul>
      </div>
      <div className={styles.header_connection}>
        {user && (
          <>
            <div className={styles.header_ring}>
              <Notification
                onClick={() => {
                  router.push(NOTIFICATIONS_PAGE);
                }}
                on={user.profile?.hasUnreadNotifications}
              />
            </div>

            <div className={styles.header_collapase}>
              <BurgerCollapse
                onClick={() => {
                  setModal(true);
                }}
              />
            </div>
            <UserProfile />
          </>
        )}
        {!user && (
          <>
            <p
              className={styles.header_signin}
              onClick={() => {
                router.push(LOGIN_PAGE);
              }}
            >
              Connexion
            </p>

            <div
              ref={signupButtonRef as React.RefObject<HTMLDivElement>}
              onClick={() => {
                router.push(INSCRIPTION_PAGE);
              }}
              className={styles.header_signup_button}
            >
              <p>Inscription</p>
            </div>

            <div className={styles.header_collapase}>
              <LazyLoadImage
                effect="blur"
                src="/assets/icons/collapaseClose.svg"
                onClick={() => {
                  setModal(true);
                }}
                alt="collapse"
              />
            </div>
          </>
        )}
      </div>
      <Modal open={modal} onClose={() => setModal(false)} keepMounted>
        <ProfileResponsiveNav
          close={() => setModal(false)}
          openSigninModal={() => {
            setSigninModal(true);
            setModal(false);
          }}
        />
      </Modal>
      <Modal open={signupModal} onClose={() => setSignupModal(false)}>
        <SignupModal
          close={() => {
            setSignupModal(false);
          }}
          openVerifyEmail={() => {
            setVerifyEmailModal(true);
          }}
        />
      </Modal>
      <Modal open={signinModal} onClose={() => setSigninModal(false)}>
        <SigninModal
          close={() => {
            setSigninModal(false);
          }}
          openVerifyEmail={() => {
            setVerifyEmailModal(true);
          }}
        />
      </Modal>
      <Modal open={verifyEmailModal} onClose={() => setVerifyEmailModal(false)}>
        <VerifyEmailModal close={() => setVerifyEmailModal(false)} />
      </Modal>
    </nav>
  );
};

export default Header;
