import React from "react";
import styled from "styled-components";

import CloseIcon from "../assets/icons/CloseIcon";
import Login from "../auth/Login";
import styles from "./Header.module.scss";

interface Props {
  close?: () => void;
  openVerifyEmail?: () => void;
}

const Close = styled.div`
  cursor: pointer;
`;

const SigninModal: React.FC<Props> = ({ close, openVerifyEmail }) => {
  return (
    <div className={styles.signup_modal_container}>
      <div className={styles.close_button}>
        <Close onClick={close}>
          <CloseIcon />
        </Close>
      </div>
      <Login
        action={() => {
          if (close) close();
          if (openVerifyEmail) openVerifyEmail();
        }}
        surrounded={false}
      />
    </div>
  );
};

export default SigninModal;
