import { useRouter } from "next/router";
import { useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { removeAuth } from "../../api/utils/token.utils";
import useAuth from "../../hooks/useAuth";
import useClickOutside from "../../hooks/useClickOutside";
import {
  CANDIDATURES_PAGE,
  FAVORITES_PAGE,
  LOGIN_PAGE,
  NOTIFICATIONS_PAGE,
  PROFILE_PAGE,
} from "../../utils/pages";
import Bell from "../assets/icons/Bell";
import Candidate from "../assets/icons/Candidate";
import Heart from "../assets/icons/Heart";
import Logout from "../assets/icons/Logout";
import ProfileIcon from "../assets/icons/ProfileIcon";
import UserAccount from "../assets/icons/UserAccount";
import NavLink from "../NavLink";
import styles from "./Header.module.scss";

function UserProfile({ number }: { number?: number }) {
  const [popupMenu, setPopupMenu] = useState(false);

  const { user, setUser, setIsAuthenticated } = useAuth();
  const profileRef = useRef<HTMLDivElement | null>(null);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();

  useClickOutside(
    profileRef,
    popupRef,
    () => {
      setPopupMenu(true);
    },
    () => {
      setPopupMenu(false);
    }
  );

  return (
    <div className={styles.profile_container}>
      <div ref={profileRef} className={styles.profile_images}>
        {user?.profile?.profileImage ? (
          <LazyLoadImage
            effect="blur"
            className={styles.rounded_image}
            src={user?.profile?.profileImage}
            alt="profile"
          />
        ) : (
          <div className={styles.rounded_image}>
            <ProfileIcon height={40} width={40} />
          </div>
        )}

        <LazyLoadImage
          effect="blur"
          src="/assets/icons/arrowDown.svg"
          alt="arrowDown"
        />
      </div>
      {popupMenu && (
        <div ref={popupRef} className={styles.popup_menu}>
          <ul>
            <li
              onClick={() => {
                router.push(PROFILE_PAGE);
              }}
            >
              <UserAccount isActive={false} />
              <NavLink isPopupMenu label="Mon profil" />
            </li>
            <li
              onClick={() => {
                router.push(CANDIDATURES_PAGE);
              }}
            >
              <Candidate isActive={false} />
              <NavLink isPopupMenu label="Mes candidatures" />
            </li>
            <li
              onClick={() => {
                router.push(NOTIFICATIONS_PAGE);
              }}
            >
              <Bell
                number={router.asPath.includes(FAVORITES_PAGE) ? 0 : number}
                onClick={() => {
                  if (number && number > 0)
                    router.push(`${FAVORITES_PAGE}?tab=3`);
                  else router.push(NOTIFICATIONS_PAGE);
                }}
                on={
                  router.asPath.includes(FAVORITES_PAGE)
                    ? false
                    : user?.profile?.hasUnreadNotifications ||
                      (number && number > 0) ||
                      false
                }
                isActive={false}
              />
              <NavLink isPopupMenu label="Mes notifications" />
            </li>
            <li
              onClick={() => {
                router.push(FAVORITES_PAGE);
              }}
            >
              <Heart isActive={false} />
              <NavLink isPopupMenu label="Mes favoris" />
            </li>
            <li
              onClick={() => {
                removeAuth();
                setUser(undefined);
                setIsAuthenticated(false);
                router.push(LOGIN_PAGE);
              }}
            >
              <Logout />
              <NavLink label="Déconnexion" />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default UserProfile;
