import React from "react";

interface Props {
  isActive?: boolean;
}

const Candidate: React.FC<Props> = ({ isActive }) => {
  return (
    <svg
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0301 0C11.4641 0 11.8803 0.172402 12.1872 0.47928C12.4941 0.786158 12.6665 1.20237 12.6665 1.63636V2.72727H11.5756V9.27273C11.5756 9.70672 11.4032 10.1229 11.0963 10.4298C10.7894 10.7367 10.3732 10.9091 9.93923 10.9091H2.30287C1.86888 10.9091 1.45266 10.7367 1.14578 10.4298C0.838906 10.1229 0.666504 9.70672 0.666504 9.27273V8.18182H9.39378V9.27273C9.39379 9.40633 9.44284 9.53527 9.53162 9.63511C9.6204 9.73495 9.74273 9.79873 9.87541 9.81436L9.93923 9.81818C10.0728 9.81816 10.2018 9.76911 10.3016 9.68034C10.4015 9.59156 10.4652 9.46923 10.4809 9.33655L10.4847 9.27273V7.09091H1.75741V1.63636C1.75741 1.20237 1.92982 0.786158 2.23669 0.47928C2.54357 0.172402 2.95979 0 3.39378 0H11.0301Z"
        fill={isActive ? "var(--color-dark-1)" : "var(--color-dark-3)"}
      />
    </svg>
  );
};

export default Candidate;
