import React from "react";

import Bell from "./Bell";
import styles from "./Icons.module.scss";

interface Props {
  on?: boolean;
  number?: number;
  onClick?: () => void;
}

const Notification: React.FC<Props> = ({ onClick, on = false, number }) => {
  return (
    <div className={styles.notification_container}>
      <Bell onClick={onClick} height={22} width={22} isActive />
      <div className={`${on && styles.notification_on}`}>
        {on && number && number > 0 ? <p>{number}</p> : null}
      </div>
    </div>
  );
};

export default Notification;
