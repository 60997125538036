import { useRouter } from "next/router";
import React from "react";
import styled from "styled-components";

import useAuth from "../../hooks/useAuth";
import {
  ARTICLES_PAGE,
  ENTREPRISES_PAGE,
  HOME_PAGE,
  INSCRIPTION_PAGE,
  OFFERS_PAGE,
  POURQUOI_SWALA_PAGE,
  PROFILE_PAGE,
} from "../../utils/pages";
import CloseIcon from "../assets/icons/CloseIcon";
import NavLink from "../NavLink";
import styles from "./Header.module.scss";

interface Props {
  close?: () => void;
  openSigninModal?: () => void;
}
const Close = styled.div`
  cursor: pointer;
`;
const ProfileResponsiveNav: React.FC<Props> = ({ close, openSigninModal }) => {
  const router = useRouter();
  const { user } = useAuth();

  return (
    <ul className={styles.profile_responsive_nav}>
      <li>
        <Close onClick={close}>
          <CloseIcon />
        </Close>
      </li>
      <li>
        <NavLink label="Accueil" path={HOME_PAGE} />
      </li>
      <li>
        <NavLink label="Offres d’emploi" path={OFFERS_PAGE} />
      </li>

      <li>
        <NavLink label="Entreprises qui recrutent" path={ENTREPRISES_PAGE} />
      </li>
      <li>
        <NavLink label="Articles" path={ARTICLES_PAGE} />
      </li>
      <li>
        <NavLink label="Pourquoi Swala ?" path={POURQUOI_SWALA_PAGE} />
      </li>

      {user && (
        <li>
          <NavLink label="Mon profil" path={PROFILE_PAGE} />
        </li>
      )}
      {!user && (
        <>
          <li onClick={openSigninModal} className={styles.signin}>
            Connexion
          </li>
          <li
            onClick={() => {
              router.push(INSCRIPTION_PAGE);
            }}
            className={styles.header_signup_responsive}
          >
            <p>Inscription</p>
          </li>
        </>
      )}
    </ul>
  );
};

export default ProfileResponsiveNav;
