import React from "react";
import styled from "styled-components";

import { RoleEnum } from "../../types/enum.types";
import CloseIcon from "../assets/icons/CloseIcon";
import Inscription from "../auth/inscription";
import styles from "./Header.module.scss";

interface Props {
  close?: () => void;
  openVerifyEmail?: () => void;
}

const Close = styled.div`
  cursor: pointer;
`;

const SignupModal: React.FC<Props> = ({ close, openVerifyEmail }) => {
  return (
    <div className={styles.signup_modal_container}>
      <div className={styles.close_button}>
        <Close onClick={close}>
          <CloseIcon />
        </Close>
      </div>
      <Inscription
        role={RoleEnum.APPLICANT}
        action={() => {
          if (close) close();
          if (openVerifyEmail) openVerifyEmail();
        }}
        surrounded={false}
      />
    </div>
  );
};

export default SignupModal;
