function Logout() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7008 6.46154C11.7008 7.21154 11.5499 7.92789 11.248 8.61058C10.9462 9.29327 10.5405 9.88221 10.0309 10.3774C9.52122 10.8726 8.9151 11.2668 8.2125 11.5601C7.5099 11.8534 6.77266 12 6.00078 12C5.22891 12 4.49167 11.8534 3.78906 11.5601C3.08646 11.2668 2.48034 10.8726 1.9707 10.3774C1.46107 9.88221 1.05534 9.29327 0.753516 8.61058C0.451693 7.92789 0.300781 7.21154 0.300781 6.46154C0.300781 5.58654 0.499935 4.76202 0.898242 3.98798C1.29655 3.21394 1.8569 2.5649 2.5793 2.04087C2.79206 1.88702 3.02832 1.82692 3.28809 1.86058C3.54785 1.89423 3.75443 2.01442 3.90781 2.22115C4.06615 2.42308 4.12676 2.65024 4.08965 2.90264C4.05254 3.15505 3.93008 3.35817 3.72227 3.51202C3.23737 3.86779 2.86257 4.30289 2.59785 4.81731C2.33314 5.33173 2.20078 5.87981 2.20078 6.46154C2.20078 6.96154 2.30098 7.4387 2.50137 7.89303C2.70176 8.34736 2.97266 8.74039 3.31406 9.07212C3.65547 9.40385 4.05996 9.66707 4.52754 9.86178C4.99512 10.0565 5.4862 10.1538 6.00078 10.1538C6.51536 10.1538 7.00645 10.0565 7.47402 9.86178C7.9416 9.66707 8.34609 9.40385 8.6875 9.07212C9.02891 8.74039 9.2998 8.34736 9.5002 7.89303C9.70059 7.4387 9.80078 6.96154 9.80078 6.46154C9.80078 5.87981 9.66842 5.33173 9.40371 4.81731C9.139 4.30289 8.76419 3.86779 8.2793 3.51202C8.07148 3.35817 7.94902 3.15505 7.91191 2.90264C7.8748 2.65024 7.93542 2.42308 8.09375 2.22115C8.24714 2.01442 8.45495 1.89423 8.71719 1.86058C8.97943 1.82692 9.21445 1.88702 9.42227 2.04087C10.1447 2.5649 10.705 3.21394 11.1033 3.98798C11.5016 4.76202 11.7008 5.58654 11.7008 6.46154ZM6.95078 0.923077V5.53846C6.95078 5.78846 6.85677 6.00481 6.66875 6.1875C6.48073 6.37019 6.25807 6.46154 6.00078 6.46154C5.74349 6.46154 5.52083 6.37019 5.33281 6.1875C5.14479 6.00481 5.05078 5.78846 5.05078 5.53846V0.923077C5.05078 0.673077 5.14479 0.456731 5.33281 0.274038C5.52083 0.0913462 5.74349 0 6.00078 0C6.25807 0 6.48073 0.0913462 6.66875 0.274038C6.85677 0.456731 6.95078 0.673077 6.95078 0.923077Z"
        fill="var(--color-dark-3)"
      />
    </svg>
  );
}

export default Logout;
