import React from "react";
import styled from "styled-components";

import CloseIcon from "../assets/icons/CloseIcon";
import VerifyEmail from "../auth/VerifyEmail";
import styles from "./Header.module.scss";

interface Props {
  close?: () => void;
}
const Close = styled.div`
  cursor: pointer;
`;

const VerifyEmailModal: React.FC<Props> = ({ close }) => {
  return (
    <div className={styles.signup_modal_container}>
      <div className={styles.close_button}>
        <Close onClick={close}>
          <CloseIcon />
        </Close>
      </div>
      <VerifyEmail />
    </div>
  );
};

export default VerifyEmailModal;
