import { MutableRefObject, useEffect } from "react";

const useClickOutside = (
  toggleRef: MutableRefObject<HTMLDivElement | null>,
  containerRef: MutableRefObject<HTMLDivElement | null>,
  actionOpen: () => void,
  actionClose: () => void
) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as Node;
      if (toggleRef.current && !containerRef.current) {
        if (toggleRef.current.contains(target)) actionOpen();
      } else {
        const toggleRefCheck1 =
          toggleRef && toggleRef.current && toggleRef.current.contains(target);

        const toggleRefCheck2 =
          toggleRef &&
          toggleRef.current &&
          !toggleRef.current.contains(target) &&
          containerRef &&
          containerRef.current &&
          !containerRef.current.contains(target);

        if (toggleRefCheck1 || toggleRefCheck2) actionClose();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleRef, containerRef, actionClose, actionOpen]);
};

export default useClickOutside;
